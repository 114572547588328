// You can customize the template with the help of this file

//Template config options
const server = {
  LOCAL: "http://localhost/sutherlin-automotive-api/public",
  LIVE: "https://api.smarterdealer.com",
  STAGING: "https://stagingapi.smarterdealer.com",
  DEV: "https://devapi.smarterdealer.com"
}


const themeConfig = {

  // ** Smart Lane Permissions
  1: [
    { "name": "Visitors", "subject": "visitors", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Blocked Visitors", "subject": "blocked-visitors", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Smart Lane Dashboard", "subject": "smart-lane-dashboard", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Valuation", "subject": "valuation", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Service Drive", "subject": "service-drive", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Dealer Website", "subject": "dealer-website", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "VIP Dashboard", "subject": "smart-lane-vip-dashboard", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],
  2: [],
  3: [],

  // ** GLobal  Admin Dealership Permissions

  globalDealershipPermissions: [
    { "name": "Dealership Users", "subject": "dealership-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Sub Dealership", "subject": "subdealership", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Events", "subject": "events", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Birthdays", "subject": "birthdays", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Anniversaries", "subject": "anniversaries", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Announcements", "subject": "announcements", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Links", "subject": "links", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Help Center", "subject": "help-center", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],

  // ** GLobal  Admin SubDealership Permissions

  globalSubDealershipPermissions: [
    { "name": "Dealership Users", "subject": "dealership-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Events", "subject": "events", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Birthdays", "subject": "birthdays", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Anniversaries", "subject": "anniversaries", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Announcements", "subject": "announcements", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Links", "subject": "links", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Help Center", "subject": "help-center", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],

  // ** DealershipUser Permissions

  DealerUserPermissions: [
    { "name": "Dealership User", "subject": "dealership-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Birthdays", "subject": "birthdays", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Anniversaries", "subject": "anniversaries", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Announcements", "subject": "announcements", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Links", "subject": "links", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Events", "subject": "events", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Help Center", "subject": "help-center", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],

  internalUserPermissions: [
    { "name": "SmarterDealer User", "subject": "smarterDealer-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Dealership User", "subject": "dealership-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Roles", "subject": "roles", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Events", "subject": "events", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Links", "subject": "links", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Birthdays", "subject": "birthdays", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Anniversaries", "subject": "anniversaries", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Announcements", "subject": "announcements", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Dealership", "subject": "dealerships", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Sub Dealership", "subject": "subdealership", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Activity Log", "subject": "activity-log", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Locked Accounts", "subject": "locked-accounts", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Help Center", "subject": "help-center", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],

  Roles: [
    { "name": "Visitors", "subject": "visitors", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Blocked Visitors", "subject": "blocked-visitors", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Dealership User", "subject": "dealership-users", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Dealership", "subject": "dealerships", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Sub Dealership", "subject": "subdealership", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Events", "subject": "events", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Birthdays", "subject": "birthdays", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Anniversaries", "subject": "anniversaries", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
    { "name": "Announcements", "subject": "announcements", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Links", "subject": "links", "list": [{ "action": "read", "checkbox": false }, { "action": "manage", "checkbox": false }] },
    { "name": "Help Center", "subject": "help-center", "list": [{ "action": "read", "checkbox": false }, { "empty": true }] },
  ],
  app: {
    appName: "SmarterDealer",
    // apiUrl: `${server["LIVE"]}`,
    apiUrl: `${server["DEV"]}`,
    // apiUrl: "http://192.168.5.40/smarterDealer-api/public",
    // apiUrl: "http://localhost:8001",
    appLogoImage: require("@src/assets/images/logo/logo.svg").default,
    storageUrl: `${server[process.env.REACT_APP_ENV]}/storage`,
  },
  allowances: {
    "1": {
      'percentage_value': 0,
      'verbiage': 'Mechanical issues, significant damage or miles over 30,000 miles a year.'
    },
    "2": {
      'percentage_value': 0,
      'verbiage': 'Minor mechanical issues, visible damage or over 25,000 miles a year.'
    },
    "3": {
      'percentage_value': 0,
      'verbiage': 'Minor interior or exterior damage. Mileage over 22,000 miles a year.'
    },
    "4": {
      'percentage_value': 5,
      'verbiage': 'Minor interior blemish. Mileage under 20,000 miles per year.'
    },
    "5": {
      'percentage_value': 6,
      'verbiage': 'Minor visible exterior damage or minor interior blemish. Mileage over 17,500 miles per year.'
    },
    "6": {
      'percentage_value': 7,
      'verbiage': 'No visible exterior or minor interior blemish. Mileage 15,000 miles a year or less.'
    },
    "7": {
      'percentage_value': 8,
      'verbiage': 'No visible exterior or interior damage, Mileage 15,000 miles a year or less.'
    },
    "8": {
      'percentage_value': 9,
      'verbiage': 'No visible exterior or interior damage, Mileage 12,500 miles a year or less.'
    },
    "9": {
      'percentage_value': 10,
      'verbiage': 'No visible exterior of interior damage, Mileage 10,000 miles a year or less.'
    },
    "10": {
      'percentage_value': 15,
      'verbiage': 'No visible exterior or interior damage, No accident history, One owner, 2 keys, Mileage 7,500 miles a year or less.'
    }
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      //backgroundColor: "ffffff" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  },
  server,
  formats: {
    date: 'LL',
    both: 'LLL',
    time: 'hh:mm A',
  },
  mysqlFormats: {
    date: 'YYYY-MM-DD'
  }
}
export default themeConfig